const translationEN = {
    title: "Charge yourself...",
    firstHeader: "Connect the charging cable",
    firstParagraph: "Connect your vehicle to the charging station using station's cable or your own. In some model stations connecting the vehicle is possible only after authorization of the user.",
    secondHeader: "Download the Nexity application.",
    secondHeaderOne: "Full power of benefits for a new users.",
    secondHeaderTwo: "or payment without registration",
    firstDropHeader: "One-Time payment",
    firstDropContent:"Too many applications on your phone? Take advantage of a one-time charging session directly from your browser.",
    firstDropContentOne: "Go to ",
    firstDropContentTwo: "Select the charging station which you want to activate (unique number)",
    firstDropContentThree: "Enter your credit card details and activate charging.",
    thirdHeaderOne: "Abracadabra",
    thirdHeaderTwo: "and you are charged",
    anyHeader: "Do you need a help?",
    anyParagraph: "+48 185 428 428",
}

export default  translationEN;