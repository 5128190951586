import React from "react";
import {isMobile, isTablet } from "react-device-detect";
import Desktop from "./containers/Desktop";
import Mobile from "./containers/Mobile";


function App() {
  
  return (
    <div>
    {isMobile && !isTablet && (<Mobile></Mobile>)}
    {isTablet &&  (<Desktop></Desktop>)}
    {!isMobile && !isTablet &&(<Desktop></Desktop>)}
    </div>
  );
}

export default App;
