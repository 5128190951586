const translationPL = {
    title: "Doładuj się...",
    firstHeader: "Podłącz kabel ładowania",
    firstParagraph: "Podłącz swój pojazd do stacji ładowania używając przewodu dostępnego na stacji lub własnego. W niektórych modelach stacji podłączenie pojazdu jest możliwe dopiero po dokonaniu autoryzacji użytkownika.",
    secondHeader: "Pobierz aplikację Nexity",
    secondHeaderOne: "Pełna moc korzyści dla nowych użytkowników.",
    secondHeaderTwo: "albo płatność bez rejestracji ",
    firstDropHeader: "Płatność jednorazowa",
    firstDropContent:"Zbyt dużo aplikacji na Twoim telefonie? Skorzystaj z jednorazowej sesji ładowania bezpośrednio z Twojej przeglądarki. ",
    firstDropContentOne: "Wejdź na ",
    firstDropContentTwo: "Wybierz stację ładowania, którą chcesz aktywować (unikalny numer)",
    firstDropContentThree: "Podaj dane swojej karty kredytowej i aktywuj ładowanie. ",
    thirdHeaderOne: "Czary mary...",
    thirdHeaderTwo: "i jesteś naładowany!",
    anyHeader: "Potrzebna Ci pomoc?",
    anyParagraph: "+48 185 428 428",
}

export default  translationPL;