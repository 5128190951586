import React, { useState } from "react";
import Collapse from "react-bootstrap/Collapse";
import translationEN from "./../translations/en";
import translationPL from "./../translations/pl";
import Select, { components } from "react-select";
import { isAndroid, isIOS } from "react-device-detect";
import languageArrow from "./../icon/language-arrow.svg";
import "./../index.css";
import "./../styles/desktop.css";
import cardLogo from "./../icon/payment-icon.svg"
import call from "./../icon/call.svg";

function Desktop() {
  const [firstDropdown, openFirstDropdown] = useState(false);
  const [secondDropdown, openSecondDropdown] = useState(false);
  const [language, setLanguage] = useState("EN");

  const options = [
    { value: "EN", label: "English", className: "language-select__option" },
    { value: "PL", label: "Polski", className: "language-select__option" },
  ];


  const customStyles = {
    control: (provided) => ({
      ...provided,
      boxShadow: "none",
      border: "none",
      background: "none",
      paddingLeft: "30px",
      paddingBottom: "20px",
      paddingTop: "10px",
      paddingRight: "0px",
      width: "100px",
      lineHeight: "22px",
      fontWeight: "400",
      fontSize: "14px",
      fontFamily: "Poppins, sans-serif",
    }),
placeholder: (base) => ({
  ...base,
  color:"#010747",
  lineHeight: "22px",
  fontWeight: "400",
  fontSize: "14px",
  fontFamily: "Poppins, sans-serif",
  
})
    ,
    menu: (base) => ({
      ...base,
      marginLeft: "30px",
      width: "100px",
      padding: "5px 10px 5px 10px",
      background: "#FFFFFF",
      boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.15)",
      borderRadius: "8px",
      lineHeight: "22px",
      fontWeight: "400",
      fontSize: "14px",
      fontFamily: "Poppins, sans-serif",
    }),
    option: (base, { isFocused, isSelected }) => {
      return {
        ...base,
        color: "#010747",
        backgroundColor: isSelected ? "#E4E4EB" : isFocused ? "none" : "",
        borderRadius: "4px",
        padding: "4px 4px 4px 9px",
        ":active": {
          backgroundColor: "#f4f4f4",
        },
      };
    },
    dropdownIndicator: (base) => {
      return {
        ...base,
        padding: "0px",
        backgroundImage: "",
      };
    },
    menuList: (base) => ({
      ...base,
    }),
    valueContainer: (base) => ({
      ...base,
      paddingLeft: "0",
      width: "10em",
    }),
    singleValue: (base) => ({
      ...base,
      paddingBottom: "1px",
      color: "#010747"
    }),
    container: (base) => ({ ...base, alignSelf: "flex-start" }),
  };

  const DropdownIndicator = (props) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <img alt="arrow language" src={languageArrow} />
        </components.DropdownIndicator>
      )
    );
  };

  const changeLanguage = (language) => {
    setLanguage(language.value);
  };

  const openGoogleStore = () => {
    let href =
      "https://play.google.com/store/apps/details?id=pl.nexity.echarge";
    window.location.assign(href);
  };

  const openAppStore = () => {
    let href = "https://apps.apple.com/app/nexity/id1457564789";
    window.location.assign(href);
  };

  return (
    <div className="desktop-container">
      <div className="desktop-left">
        <div className="desktop-left__wrapper">
        <div className="header">
        <div className="header__logo"></div>
        <div className="header__title">
          {language === "EN" && translationEN.title}
          {language === "PL" && translationPL.title}
        </div>
      </div>

      <div className="dropdown-list">
        <div
          className="dropdown-list__main-wrapper"
          onClick={() =>openFirstDropdown(!firstDropdown)}
        >
          <div className="dropdown-list__wrapper">
            <div className="dropdown-list__point">1</div>
            <p className="dropdown-list__text">
              {language === "EN" && translationEN.firstHeader}
              {language === "PL" && translationPL.firstHeader}
            </p>
          </div>

          {!firstDropdown && (
            <div
              aria-controls="example-collapse-text1"
              aria-expanded={firstDropdown}
              className="dropdown__arrow-bottom"
            ></div>
          )}
          {firstDropdown && (
            <div
              aria-controls="example-collapse-text1"
              aria-expanded={firstDropdown}
              className="dropdown__arrow-top"
            ></div>
          )}
        </div>
        <Collapse in={firstDropdown}>
          <div className="dropdown__collapse-text" id="example-collapse-text1">
            {language === "EN" && translationEN.firstParagraph}
            {language === "PL" && translationPL.firstParagraph}
          </div>
        </Collapse>
      </div>

      <div className="dropdown-list flex-d-column align-items-flex-end d-flex">
        <div className="dropdown-list__wrapper">
          <div className="dropdown-list__point">2</div>
          <p className="dropdown-list__text">
            {language === "EN" && translationEN.secondHeader}
            {language === "PL" && translationPL.secondHeader}
          </p>
        </div>



          <div className="custom-text__container">
          {!isIOS && !isAndroid && (
                    <div>
                      <div
                        className="dropdown__store-image"
                        onClick={openAppStore}
                      ></div>
                      <div
                        className="dropdown__play-image"
                        onClick={openGoogleStore}
                      ></div>
                    </div>
                  )}
                  {isIOS && (
                    <div
                      className="dropdown__store-image"
                      onClick={openAppStore}
                    ></div>
                  )}
                  {isAndroid && (
                    <div
                      className="dropdown__play-image"
                      onClick={openGoogleStore}
                    ></div>
                  )}
                    <p className="custom-text1">
                    {language === "EN" && translationEN.secondHeaderOne}
                    {language === "PL" && translationPL.secondHeaderOne}
                      </p>
                  <p className="custom-text2">
                  {language === "EN" && translationEN.secondHeaderTwo}
                  {language === "PL" && translationPL.secondHeaderTwo}
                  </p>
          </div>


        {!secondDropdown && (
          <div className="dropdown__wrapper" onClick={() => openSecondDropdown(!secondDropdown)}>
            <div className="dropdown mt-15">
              <div className="dropdown__content-wrapper">
                <div>
                  <div className="dropdown__title">
                    <img src={cardLogo} alt="card logo" className="dropdown__icon"/>
                    {language === "EN" && translationEN.firstDropHeader}
                    {language === "PL" && translationPL.firstDropHeader}
                  </div>
                </div>
                <div
                  
                  aria-controls="example-collapse-text2"
                  aria-expanded={secondDropdown}
                  className="dropdown__arrow-bottom"
                ></div>
              </div>
            </div>
          </div>
        )}

        {secondDropdown && (
          <div className="dropdown__wrapper" onClick={() => openSecondDropdown(!secondDropdown)}>
            <div className="dropdown mt-15">
              <div className="dropdown__content-wrapper">
                <div>
                <div className="dropdown__title">
                <img src={cardLogo} alt="card logo" className="dropdown__icon"/>
                    {language === "EN" && translationEN.firstDropHeader}
                    {language === "PL" && translationPL.firstDropHeader}
                  </div>
                </div>
                <div
                  aria-controls="example-collapse-text2"
                  aria-expanded={secondDropdown}
                  className="dropdown__arrow-top"
                ></div>
              </div>

              <Collapse in={secondDropdown}>
                <div
                  className="dropdown__collapse-text2"
                  id="example-collapse-text2"
                >
                  
                  <p>
                    {language === "EN" && translationEN.firstDropContent}
                  {language === "PL" && translationPL.firstDropContent}
                  </p>
                  <div className="dropdown__list-container">
                  <ol className="dropdown__list">
                    <li className="dropdown__list-element">
                    {language === "EN" && translationEN.firstDropContentOne}
                  {language === "PL" && translationPL.firstDropContentOne}
                  <a style={{color: "#14C364"}} href="https://www.chge.io">www.chge.io</a>
                    </li>
                    <li className="dropdown__list-element">
                    {language === "EN" && translationEN.firstDropContentTwo}
                  {language === "PL" && translationPL.firstDropContentTwo}
                    </li>
                    <li className="dropdown__list-element">
                    {language === "EN" && translationEN.firstDropContentThree}
                  {language === "PL" && translationPL.firstDropContentThree}
                    </li>
                  </ol>
                  </div>
                </div>
              </Collapse>
            </div>
          </div>
        )}
      </div>

      <div className="dropdown-list dropdown-list--no-border">
        <div
          className="dropdown-list__main-wrapper"
        >
          <div className="dropdown-list__wrapper">
            <div className="dropdown-list__point">3</div>
            <p className="dropdown-list__text">
              
              <p className="dropdown-list__special">
              {language === "EN" && translationEN.thirdHeaderOne}
              {language === "PL" && translationPL.thirdHeaderOne}
              </p>
              <p className="dropdown-list__special">
              {language === "EN" && translationEN.thirdHeaderTwo}
              {language === "PL" && translationPL.thirdHeaderTwo}
              </p>
            </p>
          </div>

        
        </div>

      </div>

      <div className="any-problems">
        <div className="any-problems__title">
          {language === "EN" && translationEN.anyHeader}
          {language === "PL" && translationPL.anyHeader}
        </div>
        <div className="any-problems_content">
          <a className="any-problems__call" href="tel:+48185428428">
            <img className="any-problems__icon" src={call} alt="call logo"/>
            {language === "PL" && translationEN.anyParagraph}
            {language === "EN" && translationPL.anyParagraph}
          </a>
        </div>
      </div>

     
        <Select
          className="language-select"
          options={options}
          value={{value: language, label: language === "EN" ? "English" : "Polski"}}
          menuPlacement="auto"
          onChange={changeLanguage}
          isSearchable={false}
          delimiter=""
          components={{
            IndicatorSeparator: () => null,
            DropdownIndicator,
          }}
          styles={customStyles}
        />
     

      
        </div>
      </div>

    </div>
  );
}

export default Desktop;
